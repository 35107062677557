
<footer class="bg pt-4 footer-small-text">
    <div class="container">
      <div class="row">
        <div class="col-md-4 mb-4">
          <button class="btn collapse-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOtrosEnlaces" aria-expanded="true" aria-controls="collapseOtrosEnlaces">
            Otros enlaces
            <span class="collapse-icon float-end"></span>
          </button>
          <div class="collapse show" id="collapseOtrosEnlaces">
            <ul id="listado">
              <li><a href="{{informacion_renglon_1().url}}" target='_blank'>{{informacion_renglon_1().label}}</a></li>
              <li><a href="{{informacion_renglon_2().url}}" target='_blank'>{{informacion_renglon_2().label}}</a></li>
              <li><a href="{{informacion_renglon_3().url}}" target='_blank'>{{informacion_renglon_3().label}}</a></li>
              <li><a href="{{informacion_renglon_4().url}}" target='_blank'>{{informacion_renglon_4().label}}</a></li>
              <li><a href="{{informacion_renglon_5().url}}" target='_blank'>{{informacion_renglon_5().label}}</a></li>
              <li><a href="{{informacion_renglon_6().url}}" target='_blank'>{{informacion_renglon_6().label}}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <button class="btn collapse-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseContacto" aria-expanded="true" aria-controls="collapseContacto">
            Ubicacion
            <span class="collapse-icon float-end"></span>
          </button>
          <div class="collapse show" id="collapseContacto">
            <p>
              Tribunal electoral de la Ciudad de Buenos Aires<br />
            </p>
            <i class="bi bi-geo-alt-fill"></i>
            <a style="color:black" href="https://www.google.com/maps/place/Av.+Corrientes+1464+Piso+29,+C1042AAO+Cdad.+Aut%C3%B3noma+de+Buenos+Aires/@-34.6031779,-58.3879246,17z/data=!4m5!3m4!1s0x95bccb4a6340ad3d:0x2103a41293933d6b!8m2!3d-34.6042285!4d-58.3874564?entry=ttu" target="_blank">{{organismo_direccion()}}</a>
          </div>
        </div>
        <div class="col-md-4 mb-4" *ngIf="showMap">
          <div class="map-responsive">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3284.038897542897!2d-58.3879246!3d-34.6031779!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccb4a6340ad3d%3A0x2103a41293933d6b!2sAv.%20Corrientes%201464%20Piso%2029%2C%20C1042AAO%20Cdad.%20Aut%C3%B3noma%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1718720528041!5m2!1ses-419!2sar"
              width="100%"
              height="250"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </footer>
  
  