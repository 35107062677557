import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { ConsultaPadronComponent } from './consulta-padron/consulta-padron.component';
// import { GestionReclamosComponent } from './gestion-reclamos/gestion-reclamos.component';
import { LayoutComponent } from './layout/layout.component';


export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'consulta-padron', loadComponent: () => import('./consulta-padron/consulta-padron.component') },
      { path: 'gestion-reclamo', loadComponent: () => import('./gestion-reclamos/gestion-reclamos.component') },
      { path: 'resultado-padron', loadComponent: () => import('./resultado-padron/resultado-padron.component') },
      { path: 'reclamo-enviado', loadComponent: () => import('./reclamo-msj/reclamo-msj.component') },
      { path: '', redirectTo: '/consulta-padron', pathMatch: 'full' },
    ],
    
  }
];




