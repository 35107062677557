import { Component, OnInit, signal,} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ParametrosService } from '../../services/parametros.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent implements OnInit {
  showMap: boolean = false;
  public informacion_renglon_1 = signal<any>('');
  public informacion_renglon_2 = signal<any>('');
  public informacion_renglon_3 = signal<any>('');
  public informacion_renglon_4 = signal<any>('');
  public informacion_renglon_5 = signal<any>('');
  public informacion_renglon_6 = signal<any>('');
  public organismo_direccion = signal<any>('');

  public otrosEnlaces: string[] = []

  constructor(private deviceDetectorService: DeviceDetectorService, private parametrosService: ParametrosService,) {
    this.showMap = !this.deviceDetectorService.isMobile();
  }

  ngOnInit(): void {
    this.obtenerParametro('informacion_renglon_1', (valor: any) => this.informacion_renglon_1?.set(JSON.parse(valor['valor'])));
    this.obtenerParametro('informacion_renglon_2', (valor: any) => this.informacion_renglon_2?.set(JSON.parse(valor['valor'])));
    this.obtenerParametro('informacion_renglon_3', (valor: any) => this.informacion_renglon_3?.set(JSON.parse(valor['valor'])));
    this.obtenerParametro('informacion_renglon_4', (valor: any) => this.informacion_renglon_4?.set(JSON.parse(valor['valor'])));
    this.obtenerParametro('informacion_renglon_5', (valor: any) => this.informacion_renglon_5?.set(JSON.parse(valor['valor'])));
    this.obtenerParametro('informacion_renglon_6', (valor: any) => this.informacion_renglon_6?.set(JSON.parse(valor['valor'])));
    this.obtenerParametro('organismo_direccion', (valor: any) => this.organismo_direccion?.set(valor['valor']));
  }

  obtenerParametro(nombre: string, callback: (valor: string | undefined) => void): void {
    this.parametrosService.obtenerParametroPorNombre(nombre).subscribe(
      valor => {
        callback(valor);
      },
      error => {
        console.error('Error al obtener el parámetro:', error);
      }
    );
  }
}


